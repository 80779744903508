<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'Ledger'" />
              </b-col>
            </b-row> -->
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/ledger`"
                title="Ledger"
                :columns="columns"
                routerpath="/master/account/ledger/addledger"
                :formOptions="formOptions"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                :viewFields="viewFields"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios';
import GoodTableColumnSearch from "../../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
import TabPosition from "../../../../components/TabView.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
    TabPosition,
  },
  data() {
    return {
      edit: "",
      add: "",
      canDelete: "",
      // tabs: [
      //   { name: 'Ledger', route: '/master/accounting/ledger', icon: 'WindIcon' },
      //   { name: 'AccountGroup', route: '/master/accounting/accountgroup', icon: 'UsersIcon' },
      //   { name: 'BankAccountMaster', route: '/master/accounting/bankaccount', icon: 'GridIcon' },
      //   { name: 'BankStatement', route: '/master/accounting/bankstatement', icon: 'FileTextIcon' },
      //   { name: 'Vendor', route: '/master/accounting/vendor', icon: 'SidebarIcon' },
      //   { name: 'Vendortype', route: '/master/accounting/vendortype', icon: 'TypeIcon' },
      //   { name: 'Vendorcategory', route: '/master/accounting/vendorcategory', icon: 'OctagonIcon' },
      //   { name: 'Broker', route: '/master/accounting/broker', icon: 'UserIcon' },
      //   { name: 'Farmer', route: '/master/accounting/farmer', icon: 'FramerIcon' },
      //   { name: 'PurchaseBuyer', route: '/master/accounting/purchasebuyer', icon: 'TriangleIcon' }
      // ],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${
          this.$route.query.id ? "Edit Ledger Master" : "Add Ledger Master"
        }`,
        // submitButtonName: "Add Plot Master",
        submitRouterPath: "/master/crm/ledger",
        gridForm: true,
        method: "post",
        action: "add",
        getEditValue: `${baseApi}/getusersbyid`,
        url: `${baseApi}/plotmaster`,
        inputFields: [
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Plot No",
            name: "plotno",
            value: "",
            type: "text",
            placeholder: "Enter Plot No",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Plot Name/Customer Name",
            name: "plotname",
            value: "",
            type: "text",
            placeholder: "Enter Name",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Plot No",
            name: "plotno",
            value: "",
            type: "text",
            placeholder: "Enter Plot No",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Plot Area Yard",
            name: "plotareayard",
            value: "",
            type: "text",
            placeholder: "Enter Plot Area Yard",
            class: "col-md-6",
            required: true,
          },
          {
            label: ".Rate Per(Sq. Yd)",
            name: "ratepersqyd",
            value: "",
            type: "text",
            placeholder: "Enter .Rate Per(Sq. Yd)",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Plot value",
            name: "plotvalue",
            value: "",
            type: "number",
            placeholder: "Enter Plot value",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Sq meters",
            name: "sqmeters",
            value: "",
            type: "number",
            placeholder: "Enter Sq meters",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Plot Area(Sq. Mtr) Carpet",
            name: "plotarea",
            value: "",
            type: "number",
            placeholder: "Enter Plot Area(Sq. Mtr) Carpet",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Sq. Feet",
            name: "sqfeet",
            value: "",
            type: "number",
            placeholder: "Enter Sq. Feet",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Project",
            name: "project",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "project",
            url: "project",
            required: true,
            responseValue: "id",
            options: [],
          },
          {
            label: "New Survey No",
            name: "newsurveyno",
            value: "",
            type: "number",
            placeholder: "Enter New Survey No",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Old Survey No",
            name: "oldsurveyno",
            value: "",
            type: "number",
            placeholder: "Enter Old Survey No",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Status",
            name: "status",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "status",
            url: "status",
            required: true,
            responseValue: "id",
            options: [],
          },
          {
            label: "Towards the North",
            name: "towardsthenorth",
            value: "",
            type: "text",
            placeholder: "Enter Towards the North",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Towards the South",
            name: "towardsthesouth",
            value: "",
            type: "text",
            placeholder: "Enter Towards the South",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Towards the East",
            name: "towardstheeast",
            value: "",
            type: "text",
            placeholder: "Enter Towards the East",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Towards the West",
            name: "towardsthewest",
            value: "",
            type: "text",
            placeholder: "Enter Towards the West",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Registration No",
            name: "registrationno",
            value: "",
            type: "text",
            placeholder: "Enter Registration No",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Registration Date",
            name: "registrationdate",
            value: new Date(),
            type: "datetime",
            placeholder: "Enter Registration date",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Na Order No",
            name: "naorderno",
            value: "",
            type: "number",
            placeholder: "Enter Na Order No",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Address",
            name: "address",
            value: "",
            type: "text",
            placeholder: "Enter Address",
            class: "col-md-6",
            required: true,
          },
          {
            label: "City",
            name: "city",
            value: "",
            type: "text",
            placeholder: "Enter city",
            class: "col-md-6",
            required: true,
          },
          {
            label: "State",
            name: "state",
            value: "",
            type: "text",
            placeholder: "Enter state",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Country",
            name: "country",
            value: "",
            type: "text",
            placeholder: "Enter Country",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Pin Code",
            name: "pincode",
            value: "",
            type: "number",
            placeholder: "Enter Pincode",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Document",
            name: "document",
            value: "",
            type: "fileinput",
            placeholder: "select Document",
            class: "col-md-6",
            required: true,
          },
        ],
      },
      viewFields:[
        {
          label:'Type',
          field:'type',
          type:'text',
          class:'col-md-4'
        },
        {
          label:'Account Group',
          field:'accountgrp',
          type:'dropdown',
          responseValue:'name',
          class:'col-md-4',
        },
        {
          label:'Title',
          field:'title',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Ledger Name',
          field:'name',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Mobile',
          field:'mobile',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Email',
          field:'email',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Alias',
          field:'alias',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Registration Type',
          field:'registrationtype',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'GSTIN NO',
          field:'gstno',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Pan No',
          field:'panno',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Bank',
          field:'bankname',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Branch',
          field:'bankbranch',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Account No',
          field:'accountno',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Tan No',
          field:'tanno',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Beneficiary Name',
          field:'beneficiaryname',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'TDS %',
          field:'tdsper',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Address Line 1',
          field:'addressline1',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Address Line 2',
          field:'addressline2',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Address Line 3',
          field:'addressline3',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Pincode',
          field:'pincode',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'City',
          field:'city',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Area',
          field:'area',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'State',
          field:'state',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Country.',
          field:'country',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Active/Inactive',
          field:'active',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Contact Person.',
          field:'contactpername',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Contact Person Number',
          field:'contactpermobile',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Credit period (In Days)',
          field:'creditlimitdays',
          type:'text',
          class:'col-md-4',
        },
        {
          label:'Select Employee',
          field:'employeelist',
          type:'dropdown',
          responseValue:'name',
          class:'col-md-4',
        },
        {
          label:'Photo',
          field:'profile_image',
          type:'attachment',
          class:'col-md-4',
        },
      ],
      columns: [
        {
          label: "ID",
          field: "id",
          filterOptions: {
            enabled: true,
            placeholder: "Search ID",
          },
          hidden: true,
        },
        {
          label: "Employee",
          field: "employeelist",
          type: "staticdropdown",
          filterOptions: {
            enabled: true,
            placeholder: "Search Employee",
          },
        },
        {
          label: "Type",
          field: "type",
          filterOptions: {
            enabled: true,
            placeholder: "Search Type",
          },
        },
        {
          label: "Title",
          field: "title",
          filterOptions: {
            enabled: true,
            placeholder: "Search Title",
          },
        },
        {
          label: "Ledger Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Ledger Name",
          },
        },
        {
          label: "Mobile",
          field: "mobile",
          filterOptions: {
            enabled: true,
            placeholder: "Search Mobile",
          },
        },
        {
          label: "Email",
          field: "email",
          type: "email",
          filterOptions: {
            enabled: true,
            placeholder: "Search Email",
          },
        },
        {
          label: "Account Group",
          field: "accountgrp",
          type: "dropdown",
          url: "getchartofaccount",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Account Group",
          },
        },
        {
          label: "GSTIN No",
          field: "gstno",
          filterOptions: {
            enabled: true,
            placeholder: "Search GSTIN No",
          },
        },
        {
          label: "Bank",
          field: "bankname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Bank",
          },
        },
        {
          label: "Branch",
          field: "bankbranch",
          filterOptions: {
            enabled: true,
            placeholder: "Search Branch",
          },
        },
        {
          label: "Account No",
          field: "accountno",
          filterOptions: {
            enabled: true,
            placeholder: "Search Account No",
          },
        },
        {
          label: "Registration Type",
          field: "registrationtype",
          filterOptions: {
            enabled: true,
            placeholder: "Search Type",
          },
        },
        {
          label: "Pan No",
          field: "panno",
          filterOptions: {
            enabled: true,
            placeholder: "Search Pan No",
          },
        },
        {
          label: "Birth Date",
          field: "birthdate",
          filterOptions: {
            enabled: true,
            placeholder: "Search date",
          },
        },
        {
          label: "Pincode",
          field: "pincode",
          filterOptions: {
            enabled: true,
            placeholder: "Search Pincode",
          },
        },
        {
          label: "Area",
          field: "area",
          filterOptions: {
            enabled: true,
            placeholder: "Search Area",
          },
        },
        {
          label: "City",
          field: "city",
          filterOptions: {
            enabled: true,
            placeholder: "Search City",
          },
        },
        {
          label: "State",
          field: "state",
          filterOptions: {
            enabled: true,
            placeholder: "Search State",
          },
        },
        {
          label: "Status",
          field: "status",
          filterOptions: {
            enabled: true,
            placeholder: "Search status",
          },
        },
         {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
         {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Ledger") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
